.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 180px; /* Set a fixed width for the cards */
}

.product-card img {
  width: 100%;
  height: 120px; /* Set a fixed height for images */
  object-fit: cover; /* Maintain aspect ratio, cropping if necessary */
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-card h3 {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #333;
}

.product-tags {
  margin-bottom: 10px;
}

.product-tags span {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #555;
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.quantity-controls button {
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  transition: background-color 0.3s;
}

.quantity-controls button:hover {
  background-color: #e9e9e9;
}

.quantity-controls input {
  width: 40px;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0 5px;
}

.add-to-cart {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: #45a049;
}
