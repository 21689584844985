.checkout-container {
  margin: 20px;
  padding: 20px;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-details h3 {
  margin: 0 0 5px 0;
  font-size: 1rem;
  color: #333;
}

.cart-item-details p {
  margin: 2px 0;
  font-size: 0.9rem;
  color: #666;
}

.remove-item {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.remove-item:hover {
  background-color: #ff3333;
}
