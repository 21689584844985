.home-container {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

.description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.shop-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.shop-button:hover {
  background-color: #45a049;
}

.carousel {
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  margin-top: 30px;
  width: 100%;
  scroll-behavior: smooth; /* Smooth scrolling */
  -webkit-overflow-scrolling: touch; /* iOS smooth scrolling */
}

.carousel-track {
  display: flex;
  gap: 10px; /* Space between items */
}

.food-item {
  min-width: 100px;
  flex-shrink: 0;
  font-size: 2rem;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: transform 0.2s;
  text-align: center;
}

.food-item:hover {
  transform: scale(1.1);
}

/* Hide scrollbars */
.carousel::-webkit-scrollbar {
  display: none;
}

.carousel {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
