.selections-container {
  margin: 20px;
  padding: 20px;
}

.category-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.category-filter label {
  cursor: pointer;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Match card width */
  gap: 20px;
}
