body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}

nav {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #4CAF50;
}

.cart-icon {
  font-size: 1.2rem;
}

.cart-icon a {
  text-decoration: none;
  color: #333;
  position: relative;
}

.cart-count {
  background-color: #ff0000;
  color: #fff;
  border-radius: 50%;
  padding: 3px 7px;
  font-size: 0.8rem;
  position: absolute;
  top: -10px;
  right: -10px;
}
